<template>
  <div class="travel-plan-detail">
    <van-row type="flex" justify="center" align="center" class="card">
      <van-col span="20">
        <van-field style="border-radius: 6px 0 0 0;" label="标题" v-model="cardInfo.title"></van-field>
        <van-field label="计划出发时间" readonly v-model="cardInfo.startTime"></van-field>
        <van-field label="计划返回时间" readonly v-model="cardInfo.endTime" style="border-radius: 0 0 0 6px;"></van-field>
      </van-col>
      <van-col span="4">
        <van-row>
          <van-icon name="envelop-o"/>
        </van-row>
        <van-row><span>推送审核</span></van-row>
<!--        <button style="background-color: #378FF2; border:none" :disabled="flag" @click="submitYkb()">推送审核-->
      </van-col>
    </van-row>

    <div class="list" v-for="(item,index) in list" v-bind:key="index">
      <van-row type="flex" justify="start" align="center">
        <van-col span="20">
          <van-row class="list-title">
            {{ item.titleName }}
          </van-row>
          <van-row>
            <van-col class="list-info" span="4">{{ item.createdPeople }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseNature }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseType }}</van-col>
            <van-col class="list-info" span="8">{{ item.project }}</van-col>
          </van-row>
          <van-row class="list-info">
            {{ item.address }}
          </van-row>
        </van-col>
        <van-col span="4" @click="to(item.to)">
          <van-icon name="edit"/>
        </van-col>
      </van-row>
    </div>
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "BulkCrmsVisitReplayDetail",
  components: {CrmTabBar},
  data() {
    return {
      cardInfo: {
        startTime: '',
        endTime: '',
        title: ''
      },
      list: []
    }
  },
  methods: {
    to(to) {
      this.$router.push(to);
    },
    init() {
      this.$ajax.post('/api/ajax/replay/getBulkCrmReplayList.json',
          {
            titleId: this.$route.params.id,
            createdPeople: this.$store.getters.userId,
            replayId: ''
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.list = res.data
            this.cardInfo.title = res.data[0].titleName
            this.cardInfo.startTime = res.data[0].plandateStart
            this.cardInfo.endTime = res.data[0].plandateEnd
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].to = '/BulkCrmsVisitReplayDetailEdit/' + this.list[i].travelPlanid
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
  }
}
</script>

<style scoped>
.travel-plan-detail {
  background: #f3f3f3;
}

.card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.list {
  background: #FFFFFF;
  padding: 5px 10px;
  margin-top: 5px;
}

.list-title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.list-info {
  padding-top: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}
</style>